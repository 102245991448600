import { PANEL_OFF_COLOR } from '../../../constants';
import { Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { memo, useCallback, useEffect, useRef } from 'react';
import { getPanelPointsFromConfig, getSnappedPosition, getRotatedLineDimensions, getRotatedLinePoints, checkIntersection } from '../PanelUtil';
import { useRefDispatch, useRefs } from 'contexts/RefContext';
import { getAllFacetPolygon } from '../util';
import Konva from 'konva';
import { setNewlyAddedPanel } from 'store/slices/PanelSlice';
import { getAbsolutePoints } from 'components/DisplayEnergy/util';
import { ORIENTATION } from 'store/slices/AddPanelModalSlice/types';
import { KonvaEventObject } from 'konva/lib/Node';

const PanelRect = () => {
	const scale = useKonvaScale();
	const { panel, allRoofSegs, rasterSolarPanels } = useSelector((state: RootState) => state.roofData.data);
	const { orientation: panelOrientation } = useSelector((state: RootState) => state.addPanelModal.data);
	const { moveStage } = useSelector((state: RootState) => state.toolNewPostions.data);

	const { konvaRef } = useRefs();
	const movablePanelRef = useRef<Konva.Line | null>(null);
	const intersectedRef = useRef<Konva.Line | null>(null);
	const refDispatch = useRefDispatch();
	const dispatch = useDispatch();

	useEffect(() => {
		refDispatch({ type: 'ADD_PANEL_REF', payload: { ref: movablePanelRef } });
	}, [movablePanelRef.current]);

	const getPanelPoints = useCallback(() => {
		let orientation: RasterRoofSegment['orientation'];
		if (panelOrientation === ORIENTATION.DEFAULT) {
			orientation = 'LANDSCAPE';
		} else if (panelOrientation === ORIENTATION.LANDSCAPE) {
			orientation = 'LANDSCAPE';
		} else {
			orientation = 'POTRAITT';
		}
		const width = panel.width;
		const height = panel.height;
		if (!konvaRef?.current) return [];
		return getPanelPointsFromConfig({ orientation, width, height }, scale);
	},
	[panel, scale, rasterSolarPanels, panelOrientation, konvaRef?.current]
	);

	const updatePanelPositionAndRotation = useCallback((e: KonvaEventObject<DragEvent>) => {
		if(moveStage) return;
		if (!konvaRef?.current || !movablePanelRef?.current) return;
		intersectedRef.current = null;

		const targetFacets = getAllFacetPolygon(konvaRef?.current);
		const intersectedFacet = checkIntersection(targetFacets, movablePanelRef?.current as Konva.Line);

		if (intersectedFacet) {
			intersectedRef.current = intersectedFacet;
			const matchingRoofSeg = allRoofSegs.find(seg => seg.id === intersectedFacet.id());
			if (matchingRoofSeg) {
				const grp = intersectedFacet.getParent();
				const panels = grp.children?.slice(1) as Konva.Line[];

				// if(!panels.length) return;
				// const dims = getRotatedLineDimensions(panels[0] as Konva.Line, matchingRoofSeg.azimuthDegrees);
				const dims = {
					width: matchingRoofSeg.panelWidth * scale.x,
					height: matchingRoofSeg.panelHeight * Math.cos(matchingRoofSeg.pitchDegrees * (Math.PI / 180)) *  scale.y
				};
				const width = dims.width, height = dims.height;
				let rotationAngle = matchingRoofSeg.azimuthDegrees;

				// const panelWidth = panels[0].getWidth(), panelHeight = panels[0].getHeight();
				const chnageOrientation = (matchingRoofSeg.orientation === 'POTRAITT' && panelOrientation === ORIENTATION.LANDSCAPE) ||
				(matchingRoofSeg.orientation === 'LANDSCAPE' && panelOrientation === ORIENTATION.PORTRAIT);

				if (chnageOrientation) {
					rotationAngle+=90;
				}
				rotationAngle %= 360;

				const points = getRotatedLinePoints(e.target.getRelativePointerPosition(), width, height, rotationAngle);
				movablePanelRef?.current.points(points);

				if (!movablePanelRef?.current) return;
				const closestEdge = getSnappedPosition(movablePanelRef.current.position(), movablePanelRef?.current, panels);

				if (closestEdge) {
					movablePanelRef?.current.position(closestEdge);
				}
			}
		} else {
			movablePanelRef.current.points(getPanelPoints());
		}
	}, [konvaRef, getPanelPoints, panelOrientation]);

	function insrtToIntersectedFacet() {
		if(moveStage) return;
		const intersectedFacet = intersectedRef.current;
		const panelToInsert = movablePanelRef?.current;
		if (!intersectedFacet || !panelToInsert || !konvaRef?.current) return;
		const matchingRoofSeg = allRoofSegs.find(seg => seg.id === intersectedFacet.id());
		if (!matchingRoofSeg) return;
		const exteriorCoords: number[][] = [];
		const coords = getAbsolutePoints(panelToInsert, scale, konvaRef?.current?.position());
		for (let i = 0; i < coords.length; i += 2) {
			exteriorCoords.push([coords[i], coords[i + 1]]);
		}
		panelToInsert.visible(false);

		dispatch(setNewlyAddedPanel({
			addedPanel: {
				exteriorCoords,
				orientation: panelOrientation,
				roofSegmentId: matchingRoofSeg.id,
			}
		}));
		intersectedRef.current = null;
	}

	return (
		<Line
			visible={false}
			ref={movablePanelRef}
			name="movable_panel"
			fill={PANEL_OFF_COLOR}
			listening={true}
			strokeWidth={1.4}
			stroke={'black'}
			points={getPanelPoints()}
			draggable={!moveStage}
			closed={true}
			onDragMove={(e) => updatePanelPositionAndRotation(e)}
			onDragEnd={() => insrtToIntersectedFacet()}

		/>
	);
};

export default memo(PanelRect);
